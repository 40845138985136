import { createStore } from "solid-js/store";
import {
  getCategories,
  getClasses,
  getLevels,
  getSettings,
  getTeachers,
} from "./directus";
import {
  Category,
  Class,
  CommentsTranslations,
  Leveli18,
  LocaleType,
  Teacher,
  WeekDay,
} from "~/types";
import { WeekDays } from "../constants";

interface GlobalState {
  settings: KeyValue;
  teachers: Teacher[];
  teacherNames: KeyValue;
  classes: Class[];
  categories: Category[];
  commentsTranslations: CommentsTranslations[];
  levels: Leveli18;
  weekdays: (locale: LocaleType) => WeekDay[];
  fetch: (locale: LocaleType) => void;
}
interface KeyValue {
  [key: string]: string;
}
export const [store, setStore] = createStore<GlobalState>({
  settings: {},
  teachers: [],
  teacherNames: {},
  classes: [],
  categories: [],
  commentsTranslations: [],
  levels: [],
  weekdays: (locale: LocaleType) =>
    WeekDays[locale || "es"].map((w, index) => ({
      id: index + 1 + "",
      label: w,
    })),
  fetch: async (locale) => {
    setStore({
      teachers: await getTeachers(),
      classes: await getClasses(),
      categories: await getCategories(locale),
      levels: await getLevels(),
      // settingsList: await getSettings(),
      settings: (await getSettings()).reduce((acc: KeyValue, t) => {
        acc[t.key] = t.value;
        return acc;
      }, {}),
    });

    setStore({
      teacherNames: store.teachers.reduce((acc: KeyValue, t) => {
        acc[t.id] = t.label;
        return acc;
      }, {}),
    });
  },
});

// export const useStore = createStore<GlobalState>((set) => ({
//   teachers: [],
//   classes: [],
//   categories: [],
//   levels: [],
//   weekdays: WeekDays["en"].map((w, index) => ({
//     id: index + 1 + "",
//     label: w,
//   })),
//   fetch: async (locale) => {
//     set({
//       teachers: await getTeachers(),
//       classes: await getClasses(),
//       categories: await getCategories(locale),
//       levels: await getLevels(),
//     });
//   },
// }));

import { Accessor, createSignal } from "solid-js";
import { Icon } from "solid-heroicons";
import { star } from "solid-heroicons/solid";

import {
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  RowSelectionState,
  ColumnFiltersState,
  ColumnDef,
  createSolidTable,
  OnChangeFn,
  FilterFn,
  getFilteredRowModel,
  Row,
} from "@tanstack/solid-table";
import { Class, TeacherWithDate } from "../types";

import { Duration, Time } from "./Duration";
import { globalState } from "../App";
import { WeekDays } from "../i18n/dict";
import { store } from "../data/store";
import { isTeacherDateInRange } from "../utils/timeHelpers";

declare module "@tanstack/table-core" {
  interface FilterFns {
    level: FilterFn<unknown>;
  }
}

// type CourseNameWithTeacherProps = {
//   course: Class;
//   teachers: Accessor<Teacher[]>;
//   selected: boolean;
// };

// const CourseNameWithTeacher = ({
//   course,
//   selected,
// }: CourseNameWithTeacherProps) => (
//   <>
//     <p class={"mt-4 text-s" + selected ? "" : "text-gray-400"}>
//       {course.teachers}
//     </p>
//   </>
// );

const sortTime = (rowA: Row<Class>, rowB: Row<Class>) =>
  rowA.original.starttime && rowB.original.starttime
    ? rowA.original.starttime.localeCompare(rowB.original.starttime)
    : 0;

const sortDay = (rowA: Row<Class>, rowB: Row<Class>) =>
  !rowA.original.weekdays[0] ||
  !rowB.original.weekdays[0] ||
  rowA.original.weekdays[0] == rowB.original.weekdays[0]
    ? sortTime(rowA, rowB)
    : rowA.original.weekdays[0] < rowB.original.weekdays[0]
    ? -1
    : 1;

const TimeCell = (props: {
  selected: boolean;
  starttime: string;
  duration: number;
}) => (
  <div class={"flex flex-col"}>
    <span class={"mt-4 text-s" + props.starttime ? "" : " text-gray-400"}>
      <Time starttime={props.starttime} duration={props.duration} />
    </span>
    <span class="text-gray-400">
      <Duration minutes={props.duration} />
    </span>
  </div>
);

interface columnsProps {
  tLevel: any;
  t: (key: string) => string;
}
const columns = ({ tLevel, t }: columnsProps): ColumnDef<Class>[] => [
  // },

  // {
  //   header: "Category",
  //   accessorKey: "category",
  //   // enableGrouping: true,
  //   id: "category",
  //   accessorFn: (row) => ({
  //     category: row.category,
  //     subCategory: row.subCategory,
  //   }),
  //   cell: (c) => (
  //     <div class="flex flex-row">
  //       <span>{tCategory(c.getValue().category)}</span>
  //       <span>{tCategory(c.getValue().subCategory)}</span>
  //     </div>
  //   ),
  // },
  // {
  //   header: "",
  //   id: "selected",
  //   accessorFn: (row) => row.selected,
  //   sortingFn: (rowA, rowB) =>
  //     rowA.getIsSelected() === rowB.getIsSelected()
  //       ? 0
  //       : !rowA.getIsSelected()
  //       ? -1
  //       : 1,
  //   cell: ({ row }) => (
  //     <div class="px-1">
  //       {row.getIsSelected() ? (
  //         <Icon path={checkBadge} class="w-4 h-4 text-purple-400" />
  //       ) : (
  //         <Icon path={minusSmall} class="w-4 h-4 text-gray-50" />
  //       )}
  //     </div>
  //   ),
  // },
  {
    header: t("level"),
    filterFn: "level",
    accessorKey: "level",
    accessorFn: (row) => ({
      level: row.level,
      formation: row.formation,
      comment: row.comment,
      // levelColor: row.levelColor,
    }),
    sortDescFirst: false,
    sortingFn: (rowA, rowB) =>
      !rowA.original.level ||
      !rowB.original.level ||
      rowA.original.level == rowB.original.level
        ? sortDay(rowA, rowB)
        : rowA.original.level < rowB.original.level
        ? -1
        : 1,

    cell: (c: any) => {
      const level = c.getValue().level;
      const levelColor = `level-${
        store.levels.find((l) => l.id == level)?.color
      }`;

      const comment = !c.getValue().comment
        ? undefined
        : c.getValue().comment[globalState().locale] || "";
      return (
        <div class="flex flex-col gap-1 pl-2">
          <div class="flex flex-row gap-2">
            <span class={`levelBadge ${levelColor}`}>
              {`${!tLevel ? level : tLevel(level)}`}
            </span>
            {c.getValue().formation == "formation" && (
              // <svg width="10" height="10" xmlns="http://www.w3.org/2000/svg">
              //   <circle
              //     class="level-formacion"
              //     cx="5"
              //     cy="5"
              //     r="0.5"
              //     fill="black"
              //   />
              // </svg>

              <Icon path={star} class="w-1 h-1 text-fuchsia-400" />
            )}
          </div>
          {comment && <span class="px-2">{comment}</span>}
        </div>
      );
    },

    // `${tLevel(c.getValue())}`,
  },
  {
    header: t("Days"),
    accessorKey: "weekDays",
    accessorFn: (row) =>
      row.weekdays.map((day) => WeekDays[globalState().locale || "es"][day]),
    // row.weekdays.map((day) => WeekDays[globalState().locale[day]]),

    sortingFn: sortDay,
    cell: (days) => (
      // <pre innerHTML={days.getValue() as string} />

      <div class="max-w-12 flex flex-wrap gap-1">
        {(days.getValue() as string[]).map((d, index) => (
          <span>
            {d}
            {(days.getValue() as string[]).length > index + 1 ? "," : ""}
          </span>
        ))}
      </div>
    ),
  },
  {
    header: t("time"),
    // accessorKey: "startTime",
    // footer: (props) => props.column.id,

    accessorFn: (row) => ({
      startTime: row.starttime,
      duration: row.duration,
    }),
    id: "starttime",
    sortingFn: (rowA, rowB) => sortTime(rowA, rowB) || sortDay(rowA, rowB),
    cell: (c) => (
      <TimeCell
        selected={c.row.getIsSelected()}
        starttime={(c.getValue() as any).startTime}
        duration={(c.getValue() as any).duration}
      />
    ),
  },
  {
    header: t("teacher"),
    id: "teachers",
    accessorFn: (row) => ({
      teachers: row.teachers,
      formacion: row.formation,
      weekdays: row.weekdays,
    }),

    // accessorKey: "teachers",
    // cell: (c) => JSON.stringify(c.getValue()),
    // ).join("."),
    cell: (c: any) => {
      const teachers: TeacherWithDate[] = c.getValue().teachers;
      const weekdays: number[] = c.getValue().weekdays;

      const splitTrimester =
        !!(c.getValue().formacion as string) && teachers.length > 1;
      const currentTrimester = splitTrimester
        ? parseInt(store.settings["trimestre"]) || undefined
        : undefined;

      const isTeacherDateInRangeForWeekdays = isTeacherDateInRange(weekdays);

      // if teachers contains start, end, then split and highlight
      const containsDate = teachers.some((t) => !!t.start && !!t.end);
      const isOneTeacherHighlightedByDate =
        containsDate && teachers.some(isTeacherDateInRangeForWeekdays);

      return (
        <div
          class="text-xs  align-middle flex flex-col gap-0"
          innerHTML={(teachers as TeacherWithDate[])
            .map((t, index) => {
              // undefined if start and end are set.
              const trimester: number | undefined =
                !!t.start && !!t.end ? undefined : t.trimester || index + 1;

              // if start is first weekday of data weekdays  will highlight
              const highlight =
                containsDate && isTeacherDateInRangeForWeekdays(t);

              const hl =
                (containsDate && highlight) ||
                (!isOneTeacherHighlightedByDate &&
                  trimester == currentTrimester);

              const startEndOrTrimester =
                !!t.start && !!t.end
                  ? `${t.start.toLocaleDateString("en-US", {
                      day: "numeric",
                      month: "short",
                    })} - ${t.end.toLocaleDateString("en-US", {
                      day: "numeric",
                      month: "short",
                    })} | `
                  : `${trimester}.Tri | `;

              return (c.table.options.meta as any)?.highlightTerms?.includes(
                t.id
              )
                ? `<strong class="m-0 p-0 whitespace-nowrap">${
                    store.teacherNames[t.id]
                  } </strong>`
                : `<p class="m-0 p-0 whitespace-nowrap ${
                    !splitTrimester || hl ? "" : "text-gray-400"
                  } ">${
                    splitTrimester
                      ? `<span class="text-gray-${
                          hl ? "300" : "600"
                        }">${startEndOrTrimester}</span>`
                      : ``
                  }${store.teacherNames[t.id]}</p>`;
            })
            .join("")}
        />
      );
    },
  },
];

const [sorting, setSorting] = createSignal<SortingState>([
  {
    id: "level",
    desc: false,
  },
]);

type TableProps = {
  data: () => Class[];
  columnFilters: Accessor<ColumnFiltersState>;
  setColumnFilters: OnChangeFn<ColumnFiltersState>;
  rowSelection: Accessor<RowSelectionState>;
  setRowSelection: OnChangeFn<RowSelectionState>;
  highlightTerms?: string[];
  t: (k: string) => string;
  tLevel: (k: number, long?: boolean) => string;
};

export const ClassesTableRows = ({
  data,
  columnFilters,
  setColumnFilters,
  rowSelection,
  setRowSelection,
  highlightTerms,
  tLevel,
  t,
}: TableProps) =>
  createSolidTable({
    debugAll: false,

    get data() {
      return data();
    },
    columns: columns({ tLevel, t }),
    enableFilters: true,
    enableRowSelection: true,
    enableColumnFilters: true,
    // filterFns: () => ({
    // type FilterFns = Record<string, (value: any, filterValue: any) => boolean>;

    filterFns: {
      level: (row, columnId, filterValue) => {
        // console.log(
        //   "level filter",
        //   row.original,
        //   row.original[columnId],
        //   filterValue
        // );

        return filterValue.length > 0
          ? filterValue.includes(row.original[columnId])
          : true;
      },
      // my: () => true,
    },
    onColumnFiltersChange: setColumnFilters,
    onRowSelectionChange: setRowSelection,
    state: {
      get columnFilters() {
        return columnFilters();
      },
      get sorting() {
        return sorting();
      },
      get rowSelection() {
        return rowSelection();
      },
    },
    meta: {
      highlightTerms,
    },
    // onStateChange(updater) {},
    onSortingChange: setSorting,

    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),

    getFilteredRowModel: getFilteredRowModel(),

    // getPaginationRowModel: getPaginationRowModel(),
    // getFacetedRowModel: getFacetedRowModel(),
    // getFacetedUniqueValues: getFacetedUniqueValues(),
    // getFacetedMinMaxValues: getFacetedMinMaxValues(),
    // filterFns: {
    //   category: (row, filterValue) =>
    //     filterValue.includes(row.getValue().category),
    // },
    debugTable: true,
  });

import { TeacherWithDate } from "~/types";

const zeroPad = (n: number | string) => String(n).padStart(2, "0");

export function toHour(minutes: number): string {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  return `${hours}h ${mins > 0 ? `${mins}m` : ""}`;
}

export function toHourMinutes(minutes: number): string[] {
  return [zeroPad(Math.floor(minutes / 60)), zeroPad(minutes % 60)];
}

// export const timeDate = (time: string) => new Date(`1970-01-01T${time}`);
export const timeDate = (time: string) => {
  const [hours, minutes] = time.split(":").map(Number);
  return new Date(Date.UTC(1970, 0, 1, hours, minutes, 0));
};

// export const toTimeString = (date: Date) =>
//   `${date.getHours().toString().padStart(2, "0")}:${date
//     .getMinutes()
//     .toString()
//     .padStart(2, "0")}`;
export const toTimeString = (date: Date) =>
  `${date.getUTCHours().toString().padStart(2, "0")}:${date
    .getUTCMinutes()
    .toString()
    .padStart(2, "0")}`;

// next day of weekday dayindex of date
export function nextDay(dayindex: number) {
  const d = new Date();
  d.setDate(d.getDate() + ((dayindex + (7 - d.getDay())) % 7));
  return d;
}

export const isTeacherDateInRange =
  (weekdays: number[]) => (t: TeacherWithDate) =>
    !!t.start &&
    !!t.end &&
    nextDay(weekdays[0]) >= t.start &&
    nextDay(weekdays.pop() || weekdays[0]) <= t.end;

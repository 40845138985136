import {
  flexRender,
  ColumnFiltersState,
  RowSelectionState,
} from "@tanstack/solid-table";

import {
  Accessor,
  For,
  Show,
  createEffect,
  createSignal,
  untrack,
  useContext,
} from "solid-js";
import { Class } from "~/types";
import { ClassesTableRows } from "./ClassesTableRows";
import { LocaleContext } from "../App";

import { store } from "../data/store";

// import { Icon } from "solid-heroicons";
// import { barsArrowUp } from "solid-heroicons/solid";

type ClassesTableProps = {
  category: string;
  // subCategory: string;

  data: () => Class[];
  setSelectionStore: (value: Record<string, string>) => void;
  selectionStore: Accessor<Record<string, string>>;
  highlightTerms?: string[];
};
export default function ClassesTable({
  category,
  // subCategory,
  data,
  setSelectionStore,
  selectionStore,
  highlightTerms,
}: ClassesTableProps) {
  const contextValue = useContext(LocaleContext);

  if (!contextValue) {
    // Handle the case where the context value is undefined or null
    return null;
  }

  const { t, tLevel } = contextValue;

  const [filter, setFilter] = createSignal<ColumnFiltersState>([]);
  const [levelFilter, setLevelFilter] = createSignal<number[]>([]);
  const [levels, setLevels] = createSignal<number[]>([]);
  const [rowSelection, setRowSelection] = createSignal<RowSelectionState>({});

  const coursesTable = ClassesTableRows({
    data,
    columnFilters: filter,
    setColumnFilters: setFilter,
    rowSelection,
    setRowSelection,
    highlightTerms,
    t,
    tLevel,
  });

  createEffect(() => {
    setLevels(
      Array.from(
        new Set(
          (data() || [])
            .filter((c) => !!c.level) // null/undefined level
            .map((course) => course.level)
            .sort()
        )
      ) as number[]
    );
  });

  createEffect(() => {
    setFilter([
      ...untrack(() => filter().filter((f) => f.id !== "level")),
      {
        id: "level",
        value: levelFilter(),
      },
    ]);
  });

  // const selectionRows = createMemo(() => {
  createEffect(() => {
    // const rowSelection = coursesTable.getState().rowSelection;
    // const k = Object.keys(rowSelection);
    // console.log(
    //   "rowSelection",
    //   k,
    //   rowSelection,

    //   k[0] && data()[Number(k[0]) as number]
    // );
    // console.log("model selected row", coursesTable.getSelectedRowModel().rows);
    setSelectionStore({
      ...untrack(() => selectionStore()),
      [`${category}`]: coursesTable
        .getSelectedRowModel()
        .rows.map((r) => r.original.id)
        .join("|"),
    });
  });

  // createEffect(() => {
  //   console.log("selectionRows effect", category, selectionRows().d);
  //   // setSearchParams( ...searchParams, {
  //   //   [category]: selectionRows().map((c) => c.id).join("|"),
  //   // });
  // });

  return (
    <div class="">
      {/* <pre>{JSON.stringify(coursesTable.getState().rowSelection)}</pre> */}
      {levels()?.length > 1 && data()?.length > 5 && (
        <div class="flex flex-wrap gap-x-4 px-4">
          <LevelSelectors
            levels={levels}
            setLevelFilter={setLevelFilter}
            levelFilter={levelFilter}
            tLevel={tLevel}
          />
          <div></div>
        </div>
      )}
      {/* <div class="mt-6 overflow-hidden border-t border-gray-100"> */}
      {/* <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8"> */}
      {/* <div class="mx-auto overflow-x-auto max-w-2xl lg:mx-0 lg:max-w-none"> */}
      <table class="table table-xs w-full text-left table-pin-rows table-pin-cols">
        <thead>
          <For each={coursesTable.getHeaderGroups()}>
            {(headerGroup) => (
              <tr class=" font-mono">
                <For each={headerGroup.headers}>
                  {(header, index) => (
                    <th
                      class={
                        "rounded-sm p-0 pl-3  " +
                        (index() > -1 ? "bg-slate-100 " : "")
                      }
                      colSpan={header.colSpan}
                    >
                      <Show when={!header.isPlaceholder}>
                        <div
                          class={
                            "flex flex-row  " +
                            (header.column.getCanSort()
                              ? "cursor-pointer select-none"
                              : undefined)
                          }
                          onClick={header.column.getToggleSortingHandler()}
                        >
                          <p>
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                          </p>
                          {{
                            asc: <p innerHTML="▲" class="text-green-300"></p>,
                            desc: <p innerHTML="▼" class="text-red-300"></p>,

                            // desc: (
                            //   <>
                            //     {/* <Icon
                            //       path={arrowDown}
                            //       style="width: 20px; color: yellow"
                            //     /> */}

                            //     {/* <barsArrowDown /> */}
                            //     <Icon
                            //       path={barsArrowDown}
                            //       style="width: 20px; color: yellow"
                            //     />
                            //   </>
                            // ),
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                      </Show>
                    </th>
                  )}
                </For>
              </tr>
            )}
          </For>
        </thead>
        <tbody>
          {/* <For each={courses}>
                  {(course, index) => (
                    <DanceClass index={index} course={course} />
                  )}
                </For> */}

          <For each={coursesTable.getRowModel().rows.slice(0, 100)}>
            {(row) => {
              return (
                <tr
                  class={
                    row.getIsSelected() ? "hover bg-base-300 text-white" : ""
                  }
                  // row click disabled
                  onclick={() => false && row.toggleSelected()}
                >
                  <For each={row.getVisibleCells()}>
                    {(cell) => (
                      <td>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    )}
                  </For>
                </tr>
              );
            }}
          </For>
        </tbody>
      </table>
      {/* </div> */}
      {/* // </div> */}
      {/* // </div> */}
      {/* <pre class="text-left"> */}
      {/* {JSON.stringify(coursesTable.getState(), null, 2)} */}
      {/* </pre> */}
    </div>
  );
}
interface LevelSelectorsProps {
  levels: () => number[];
  levelFilter: () => number[];
  setLevelFilter: (value: number[]) => void;
  tLevel: (k: number, long?: boolean) => string;
}

const LevelSelectors = ({
  levels,
  levelFilter,
  setLevelFilter,
  tLevel,
}: LevelSelectorsProps) => {
  return (
    <div class="grid grid-cols-2 gap-x-4">
      <For each={levels()}>
        {(level) => {
          const levelColor = `level-${
            store.levels.find((l) => l.id == level)?.color
          }`;

          return (
            typeof level == "number" && (
              <label class="flex justify-self-start items-start space-x-2 p-1">
                <input
                  type="checkbox"
                  class="h-5 w-5 mt-1"
                  value={level}
                  onChange={(e) => {
                    const { checked, value } = e.currentTarget;
                    const intValue = parseInt(value);
                    if (checked) {
                      setLevelFilter([...levelFilter(), intValue]);
                    } else {
                      setLevelFilter(
                        levelFilter().filter((v) => v !== intValue)
                      );
                    }
                  }}
                />
                <div class="flex flex-col">
                  <span class={`levelBadge ${levelColor} mb-1`}>
                    {tLevel(level)}
                  </span>
                  <div class="text-sm px-4">{tLevel(level, true)}</div>
                </div>
              </label>
            )
          );
        }}
      </For>
    </div>
  );
};
